<template>
  <div class="p-3 text-center h4">Yapım Aşamasında</div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.form = ref({
      k_no: null,
      kullanici_adi: null,
      sifre: null,
      port: null,
      host: null,
    });

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('epostaAyarlariGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('epostaAyarlariEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
